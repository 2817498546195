import React, { Component } from 'react';
import axios from 'axios';
import logo from '../../images/home/logo.png';
import logoWhite from '../../images/home/logo-white.png';
import restartVisual from '../../images/home/restart-visual.png';
import captchaImage from '../../images/home/captcha.png';
import './App.css';

class App extends Component {
  state = {
    submitted: false,
    successfully: false,
    error: null,
    invitationType: null,
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      submitted: true,
      successfully: false,
      error: null,
    });

    const { invitationType } = this.state;
    const email = event.target.elements.email.value;
    const firstname = event.target.elements.firstname.value;
    const lastname = event.target.elements.lastname.value;
    const birthdate = event.target.elements.birthdate.value;
    const captcha = parseInt(event.target.elements.captcha.value, 0);
    let invitationName;
    let invitationTypeToSave;
    let allergies;

    if (event.target.elements.invitationName) {
      invitationName = event.target.elements.invitationName.value;
    }

    if (invitationType) {
      invitationTypeToSave = invitationType;
    }

    if (event.target.elements.allergies.value.trim().length > 0) {
      allergies = event.target.elements.allergies.value;
    }

    if (email && firstname && lastname && birthdate && captcha && captcha === 14) {
      this.handleSignUp(email, firstname, lastname, birthdate, allergies, invitationTypeToSave, invitationName);
    } else if (captcha !== 14) {
      this.setState({
        submitted: false,
        successfully: false,
        error: {
          message: 'La réponse au captcha n\'est pas correcte.',
        },
      });
    } else {
      this.setState({
        submitted: false,
        successfully: false,
        error: {
          message: 'Tout les champs sont obligatoires.',
        },
      });
    }
  };

  handleSignUp = (email, firstname, lastname, birthdate, allergies, invitationType, invitationName) => {
    // axios.post('https://api-dev.revival-jeunes.be/registrations', {
    axios.post('https://api.revival-jeunes.be/registrations', {
      email,
      firstname,
      lastname,
      birthdate,
      allergies,
      invitationType,
      invitationName,
    })
      .then(() => {
        this.setState({
          submitted: false,
          successfully: true,
          error: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChecked = (event) => {
    const { invitationType } = this.state;

    if ((!invitationType || invitationType === 'INVITED') && event.target.value === 'INVITE' && event.target.checked) {
      this.setState({
        invitationType: 'INVITE',
      });
    } else if ((!invitationType || invitationType === 'INVITE') && event.target.value === 'INVITED' && event.target.checked) {
      this.setState({
        invitationType: 'INVITED',
      });
    } else {
      this.setState({
        invitationType: null,
      });
    }
  }

  resetForm = () => {
    this.setState({
      submitted: false,
      successfully: false,
      error: null,
    });
  }

  render() {
    const {
      submitted,
      successfully,
      error,
      invitationType,
    } = this.state;
    let submitButton = (
      <input
        type="submit"
        value="M'inscrire"
        className="App-submit"
      />
    );
    let inviteField = null;
    let errorMessage = null;

    if (submitted) {
      submitButton = (
        <p>Envoie en cours...</p>
      );
    }

    if (invitationType) {
      inviteField = (
        <fieldset className="App-fieldset">
          <label htmlFor="invitationName" className="App-label">Prénom et nom*
            <input type="text" placeholder="Prénom et nom de l'ami" id="invitationName" className="App-input" required />
          </label>
        </fieldset>
      );
    }

    if (error) {
      errorMessage = error.message;
    }

    let signUpForm = (
      <form onSubmit={this.handleSubmit} className="App-signup">
        <h4 className="App-waiting-list-title">Les inscriptions sont complètes. En vous inscrivant vous serez placé sur notre liste d’attente.</h4>
        <fieldset className="App-fieldset">
          <label htmlFor="firstname" className="App-label">Prénom*
            <input type="text" placeholder="Ton prénom" id="firstname" className="App-input" required />
          </label>
        </fieldset>
        <fieldset className="App-fieldset">
          <label htmlFor="lastname" className="App-label">Nom*
            <input type="text" placeholder="Ton nom" id="lastname" className="App-input" required />
          </label>
        </fieldset>
        <fieldset className="App-fieldset">
          <label htmlFor="email" className="App-label">Email*
            <input type="text" placeholder="Ton adresse email" id="email" className="App-input" required />
          </label>
        </fieldset>
        <fieldset className="App-fieldset">
          <label htmlFor="birthdate" className="App-label">Date de naissance (ex: 13-06-1992)*
            <input type="text" placeholder="Ta date de naissance" id="birthdate" className="App-input" required />
          </label>
        </fieldset>
        <fieldset className="App-fieldset">
          <label htmlFor="allergies" className="App-label">
            Allergies alimentaires à nous signaler
            <input type="text" id="allergies" className="App-input" />
          </label>
        </fieldset>
        <fieldset className="App-fieldset">
          <label htmlFor="invite" className="App-label">
            <input type="radio" id="invite" name="invitation" className="App-input" value="INVITE" onClick={this.handleChecked} checked={invitationType === 'INVITE'} readOnly />
            &nbsp;J’invite un ami
          </label>
        </fieldset>
        <fieldset className="App-fieldset">
          <label htmlFor="invited" className="App-label">
            <input type="radio" id="invited" name="invitation" className="App-input" value="INVITED" onClick={this.handleChecked} checked={invitationType === 'INVITED'} readOnly />
            &nbsp;Je suis invité par un ami
          </label>
        </fieldset>
        {inviteField}
        <fieldset className="App-fieldset">
          <label htmlFor="captcha" className="App-label">Captcha (donnez le total)*
            <img src={captchaImage} alt="Captcha" className="App-captcha" />
            <input type="text" placeholder="Réponse au captcha" id="captcha" className="App-input" required />
          </label>
        </fieldset>
        <fieldset>
          <p className="App-formError">{ errorMessage }</p>
          {submitButton}
        </fieldset>
      </form>
    );

    if (!submitted && !error && successfully) {
      signUpForm = (
        <div className="App-signup App-signup-successfully">
          <h3><span role="img" aria-label="Sign Up successful">🎉</span> Félicitations, tu es bien ajouté à la liste d’attente ! <span role="img" aria-label="Sign Up successful">🎉</span></h3>
          <p>Nous prendrons contact avec toi si une place vient à se libérer.</p>
          <button type="button" className="App-button" onClick={this.resetForm}>C’est compris, merci</button>
        </div>
      );
    }

    return (
      <div className="App">
        <nav className="App-nav">
          <h1><img src={logoWhite} alt="Revival" width="160" /></h1>
        </nav>
        <header className="App-header">
          <h1><img src={restartVisual} alt="Restart" className="App-header-visual" /></h1>
          <p className="App-header-date">Du vendredi 21 février au mardi 25 février 2020</p>
        </header>
        <div className="App-info-container">
          <section>
            <h2 className="App-signup-title">Infos</h2>
            <div className="App-box">
              <h3>Où ?<br />Au Camp de Limauges</h3>
              <p>Chemin de Bigaumont 2, 1341 Céroux-Mousty.</p>
              <p>Quand ?<br />Du vendredi 21 février au mardi 25 février 2020.</p>
              <p>Tranche d’âge : 12 - 30 ans</p>
            </div>
          </section>
          <section>
            <h2 className="App-signup-title">Combien ?</h2>
            <div className="App-box">
              <h3>Prix&nbsp;: 90€</h3>
              <p><strong>Compte: BE54 0018 1787 7097</strong>. Inscription validée après paiement des 90€.</p>
              <p>Une réduction de <strong>10€</strong> sera appliquée par membre d’une même famille, à partir de la 2ème inscription.</p>
            </div>
          </section>
        </div>
        <section>
          <h2 className="App-signup-title">Inscris-toi</h2>
          <div className="App-signup">
            {/* <iframe title="Sign up form" src="http://localhost:3001/events/3b2d7664-fbff-459b-b16b-af3ec22e7e16" style={{width: "100%", height: "878px"}} /> */}
            <iframe title="Sign up form" src="https://app.lofe.co/events/f986e922-c96e-42b6-883c-f6b618d14a36" style={{width: "100%", height: "980px"}} />
          </div>
          <div className="App-bottomLogo">
            <img src={logo} alt="Revival" width="160" />
          </div>
        </section>
      </div>
    );
  }
}

export default App;
